body {
  text-align: center;
  background: #333;
  overflow: hidden;
  margin: 0;
}

img {
  width: 100%;
}

.hero {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  will-change: clip-path;
  clip-path: inset(0 0 0 0);
  transition: clip-path ease-in .4s;
}

.hero.hide {
  clip-path: inset(0 0 100vh 0);
  pointer-events: none;
}

.hero.hide .name {
  cursor: default;
}

.name {
  cursor: pointer;
  padding: 3rem;
  width: 50rem;
}

.name svg {
  fill: none;
  stroke: #fff;
  stroke-width: 4px;
  width: 100%;
}

.name:hover svg {
  fill: #fff;
}